<template>
  <IconSpinner :class="['text-p-500 animate-spin', sizeClasses]" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import IconSpinner from '~/assets/icons/Spinner.svg'

const props = withDefaults(
  defineProps<{
    size?: 'extra-small' | 'small' | 'large'
  }>(),
  {
    size: 'large',
  },
)

const sizeClasses = computed(() => {
  const sizes = {
    'extra-small': 'w-5 h-5',
    small: 'w-8 h-8',
    large: 'w-16 h-16',
  }
  return sizes[props.size]
})
</script>
